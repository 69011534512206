import { template as template_b5beb8cc7c6c43b4a20538f384d28d68 } from "@ember/template-compiler";
const FKLabel = template_b5beb8cc7c6c43b4a20538f384d28d68(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
