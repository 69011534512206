import { template as template_307c22b78eb746949f40778d266028d7 } from "@ember/template-compiler";
const FKText = template_307c22b78eb746949f40778d266028d7(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
